import React from "react";
import useStyles from "./style";
import { Link, useParams, useHistory } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MuiLink from "@mui/material/Link";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import no_image_found from "../../../assets/images/no_image_found.png";
import { CardContent, Stack } from "@mui/material";

const isFloat = (num) => {
  return !Number.isInteger(num);
};

const ProductListView = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const {
    product,
    productId,
    price,
    bpp_id,
    location_id,
    bpp_provider_id,
    bpp_provider_descriptor,
    show_quantity_button = true,
    onUpdateCart = () => { },
    handleAddToCart = () => { },
    getProductDetails,
    productLoading,
  } = props;
  const { id, descriptor, provider_details } = product;
  const { name: provider_name } = bpp_provider_descriptor;
  const {
    name: product_name,
    images,
    short_desc: product_description,
    symbol,
  } = descriptor;

  return (
    <Card className="cu_card">
      <Grid className="wis_io_list">
        <Typography variant="caption" className="">
          <FavoriteBorderIcon/>
        </Typography>
      </Grid>
      <CardContent>
      <Grid container spacing={2} className="">
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          <img
            className={classes.productImage}
            src={symbol ? symbol : no_image_found}
            alt={`sub-cat-img-${bpp_id}`}
            onError={(e) => { e.target.src = no_image_found; }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={9} lg={9} xl={9}
          className=""
        >
          <Typography
            variant="h5"
            className="ell_single"
            onClick={() =>
              history.push(`/application/products?productId=${productId}`)
            }
          >
            {product_name}
          </Typography>
          {/* <Typography variant="body1" className={classes.providerTypoList}>
            {provider_name}
          </Typography> */}
          <Typography variant="h4" className={classes.priceTypoList}>
            {`₹${isFloat(Number(price?.value))
              ? Number(price?.value).toFixed(2)
              : Number(price?.value).toFixed(0)
              }`}
          </Typography>
          <Typography
            variant="body"
            className={classes.descriptionTypoList}
            sx={{minHeight:48}}
          >
            {product_description}
          </Typography>
          <Stack mt={2} direction="row" justifyContent="space-between">
            <MuiLink
              component={Link}
              to={`/application/products?productId=${productId}`}
            >
              View details
            </MuiLink>
            <Button
              className=""
              variant="contained"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                getProductDetails(productId).then((data) =>
                  handleAddToCart(data, true)
                );
              }}
            >
              Add to cart
            </Button>

            {/* <Button
            className={classes.addToCartBtn}
            variant="contained"
            onClick={(e) => {
              e.stopPropagation();
              getProductDetails(productId).then((data) => handleAddToCart(data, true, true));
            }}
          >
            Buy Now
          </Button> */}
          </Stack>
        </Grid>
      </Grid>
      </CardContent>
    </Card>
  );
};

export default ProductListView;
