import React, { useState, useEffect } from "react";
import useStyles from "./style";

import {Grid,Card} from "@mui/material";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as CartIcon } from "../../../assets/images/cart.svg";
import no_image_found from "../../../assets/images/no_image_found.png";
import { useHistory } from "react-router-dom";
import { postCall } from "../../../api/axios";
import { getValueFromCookie } from "../../../utils/cookies";
import { Button, Stack } from "@mui/material";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

const isFloat = (num) => {
  return !Number.isInteger(num);
};

const ProductGridView = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const {
    product,
    productId,
    price,
    bpp_id,
    location_id,
    bpp_provider_id,
    bpp_provider_descriptor,
    show_quantity_button = true,
    onUpdateCart = () => { },
    handleAddToCart = () => { },
    getProductDetails,
    productLoading,
  } = props;
  const { id, descriptor, provider_details } = product;
  const { name: provider_name } = bpp_provider_descriptor;
  const { name: product_name, images, symbol } = descriptor;

  return (
    <div
      className={classes.productItemContainer}
      onClick={() =>
        history.push(`/application/products?productId=${productId}`)
      }
      style={{position:'relative'}}
    >
      <Card className="cu_card" sx={{pb:4.5}}>
        <Grid className="wis_io">
          <Typography variant="caption" className="">
            <FavoriteBorderIcon/>
          </Typography>
        </Grid>
        <Stack sx={{
          
        }} p={2} justifyContent="space-between">
          <img
            className={classes.productImage}
            src={symbol ? symbol : no_image_found}
            alt={`sub-cat-img-${bpp_id}`}
            sx={{
              objectFit: "cover", // Ensures the image covers the area proportionally
              margin: "auto",
            }}
            onError={(e) => { e.target.src = no_image_found; }}
          />
          {/* <Button
          fullWidth
          className={classes.buyNowButton}
          variant="contained"
          onClick={(e) => {
            e.stopPropagation();
            // getProductDetails(productId).then((data) => handleAddToCart(data, true, true));
          }}
        >
          Buy Now
        </Button> */}
          <Stack spacing={1}>
            <Typography variant="h6" className="ell_single" sx={{ fontSize: 14, pt:2 }}>
              {product_name}
            </Typography>
            <Typography variant="subtitle1">{provider_name}</Typography>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h6" className="" sx={{mt:0}}>
                {`₹${isFloat(Number(price?.value))
                  ? Number(price?.value).toFixed(2)
                  : Number(price?.value).toFixed(0)
                  }`}
              </Typography>
            </Stack>
          </Stack>

        </Stack>
      </Card>
      <div className="ad_cr_gir" style={{ position:'relative' }}>
        <Button variant="contained"
          onClick={(e) => {
            e.stopPropagation();
            getProductDetails(productId).then((data) =>
              handleAddToCart(data, true)
            );
          }} size="small">
          Add to cart
        </Button>
      </div>
    </div>
  );
};



export default ProductGridView;
