import React from "react";
import useStyles from "./style";
import { Box, Divider } from "@mui/material";
import { Container, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import logo from "../../../assets/images/AppLogo.svg";
import { ReactComponent as CallIcon } from "../../../assets/images/call.svg";
import { ReactComponent as WhatsappIcon } from "../../../assets/images/whatsapp.svg";
import appStoreImage from "../../../assets/images/appStore.png";
import playStoreImage from "../../../assets/images/playStore.png";
import { removeCookie } from "../../../utils/cookies";
import { categoryList } from "../../../constants/categories";
import { useHistory, useLocation } from "react-router-dom";
import { Link } from "@mui/material";
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Payment_logo from "../../../assets/images/py_crd.png";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

const Footer = () => {
  const classes = useStyles();
  const history = useHistory();
  const locationData = useLocation();
  const useQuery = () => {
    const { search } = locationData;
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };
  let query = useQuery();

  const updateQueryParams = (catName) => {
    if (locationData.search === "" && query.get("c") === null) {
      history.push(`/application/products?c=${catName}`);
    } else {
      const params = new URLSearchParams({});
      params.set("c", catName);
      history.replace({
        pathname: locationData.pathname,
        search: params.toString(),
      });
    }
  };
  const currentYear = new Date().getFullYear();
  return (
    <>
    <Box component="footer" className="">
      <Box sx={{ backgroundColor:" #103f68", overflow:'hidden', position:'relative', pt:8, pb:4 }}>
        <Grid container spacing={2} sx={{justifyContent:'center'}}>
          <Grid item xs={12} sm={12} md={11.1} className="ft_st">
            <Container maxWidth="xl" className="">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={2.4} lg={2.4} xl={2.4}>
                  <Typography variant="h6" color="white" sx={{fontSize:18}}>
                    About OrderBazaar
                  </Typography>
                  <ul className={classes.listContainer} style={{paddingLeft:0}}>
                    <li className={classes.listStyle}>
                      <Typography
                        className={classes.itemDetailsLabel}
                        variant="body"
                        component="div"
                        color="white"
                      >
                        <Link className={classes.itemDetailsLabel} href="/about-us">
                          About Us
                        </Link>
                      </Typography>
                    </li>
                    <li className={classes.listStyle}>
                      <Typography
                        className={classes.itemDetailsLabel}
                        variant="body"
                        component="div"
                        color="white"
                      >
                        <Link
                          className={classes.itemDetailsLabel}
                          href="#"
                        >
                          Careers
                        </Link>
                      </Typography>
                    </li>
                    <li className={classes.listStyle}>
                      <Typography
                        className={classes.itemDetailsLabel}
                        variant="body"
                        component="div"
                        color="white"
                      >
                        <Link className={classes.itemDetailsLabel} href="#">
                          Press Releases
                        </Link>
                      </Typography>
                    </li>
                    <li className={classes.listStyle}>
                      <Typography
                        className={classes.itemDetailsLabel}
                        variant="body"
                        component="div"
                        color="white"
                      >
                        <Link
                          className={classes.itemDetailsLabel}
                          href="#"
                        >
                          Blog
                        </Link>
                      </Typography>
                    </li>
                  </ul>
                </Grid>
                <Grid item xs={12} sm={12} md={2.4} lg={2.4} xl={2.4}>
                  <Typography variant="h6" color="white" sx={{fontSize:18}}>
                    Customer Services
                  </Typography>
                  <ul className={classes.listContainer} style={{paddingLeft:0}}>
                    <li className={classes.listStyle}>
                      <Typography
                        className={classes.itemDetailsLabel}
                        variant="body"
                        component="div"
                        color="white"
                      >
                        <Link className={classes.itemDetailsLabel} href="/contact-us">
                          Contact Us
                        </Link>
                      </Typography>
                    </li>
                    <li className={classes.listStyle}>
                      <Typography
                        className={classes.itemDetailsLabel}
                        variant="body"
                        component="div"
                        color="white"
                      >
                        <Link
                          className={classes.itemDetailsLabel}
                          href="/faq"
                        >
                          FAQ
                        </Link>
                      </Typography>
                    </li>
                    <li className={classes.listStyle}>
                      <Typography
                        className={classes.itemDetailsLabel}
                        variant="body"
                        component="div"
                        color="white"
                      >
                        <Link className={classes.itemDetailsLabel} href="#">
                          Shipping Info
                        </Link>
                      </Typography>
                    </li>
                    <li className={classes.listStyle}>
                      <Typography
                        className={classes.itemDetailsLabel}
                        variant="body"
                        component="div"
                        color="white"
                      >
                        <Link
                          className={classes.itemDetailsLabel}
                          href="#"
                        >
                          Track Order
                        </Link>
                      </Typography>
                    </li>
                  </ul>
                  {/* <Box component={"div"} className={classes.categoryDivider} /> */}
                  {/* <ul className={classes.listContainer}>
                    {categoryList.map((item, index) => (
                      <li
                        key={`category-${index}`}
                        className={classes.listStyle}
                        onClick={() => updateQueryParams(item.routeName)}
                      >
                        <Typography
                          className={classes.itemDetailsLabel}
                          variant="body"
                          component="div"
                          color="white"
                        >
                          {item.shortName}
                        </Typography>
                      </li>
                    ))}
                  </ul> */}
                </Grid>
                <Grid item xs={12} sm={12} md={2.3} lg={2.3} xl={2.3}>
                  <Typography variant="h6" color="white" sx={{fontSize:18}}>
                    Policies
                  </Typography>
                  {/* <Box component={"div"} className={classes.serviceDivider} /> */}
                  <ul className={classes.listContainer} style={{paddingLeft:0}}>
                    <li className={classes.listStyle}>
                      <Typography
                        className={classes.itemDetailsLabel}
                        variant="body"
                        component="div"
                        color="white"
                      >
                        <Link className={classes.itemDetailsLabel} href="/cancel-return-policy">
                          Return Policy
                        </Link>
                      </Typography>
                    </li>
                    <li className={classes.listStyle}>
                      <Typography
                        className={classes.itemDetailsLabel}
                        variant="body"
                        component="div"
                        color="white"
                      >
                        <Link
                          className={classes.itemDetailsLabel}
                          href="/privacy-policy"
                        >
                          Privacy Policy
                        </Link>
                      </Typography>
                    </li>
                    <li className={classes.listStyle}>
                      <Typography
                        className={classes.itemDetailsLabel}
                        variant="body"
                        component="div"
                        color="white"
                      >
                        <Link
                          className={classes.itemDetailsLabel}
                          href="/terms-and-condition"
                        >
                          Terms of Use
                        </Link>
                      </Typography>
                    </li>
                    <li className={classes.listStyle}>
                      <Typography
                        className={classes.itemDetailsLabel}
                        variant="body"
                        component="div"
                        color="white"
                      >
                        <Link className={classes.itemDetailsLabel} href="/contact-us">
                          Security
                        </Link>
                      </Typography>
                    </li>
                    {/* <li className={classes.listStyle}>
                      <Typography
                        className={classes.itemDetailsLabel}
                        variant="body"
                        component="div"
                        color="white"
                      >
                        <Link
                          className={classes.itemDetailsLabel}
                          href="/cancel-return-policy"
                        >
                          Cancellation & Return Policy
                        </Link>
                      </Typography>
                    </li> */}
                  </ul>
                </Grid>
                <Grid item xs={12} sm={12} md={2.4} lg={2.4} xl={2.4}>
                  <Typography variant="h6" color="white" sx={{fontSize:18}}>
                    Address Info
                  </Typography>
                  {/* <Box component={"div"} className={classes.categoryDivider} /> */}
                  <div className={classes.contactUsContainer}>
                    <div className={classes.contactUsItem}>
                      <div>
                        <WhatsappIcon />
                      </div>
                      <div className={classes.itemDetailsContainer}>
                        {/* <Typography
                          className={classes.itemDetailsLabel}
                          variant="body"
                          component="div"
                          color="white"
                        >
                          Whats App
                        </Typography> */}
                        <Typography
                          className={classes.itemDetailsValue}
                          variant="body"
                          component="div"
                          color="white"
                        >
                          +1 202-918-2132
                        </Typography>
                      </div>
                    </div>
                    <div className={classes.contactUsItem}>
                      <div>
                        <CallIcon />
                      </div>
                      <div className={classes.itemDetailsContainer}>
                        {/* <Typography
                          className={classes.itemDetailsLabel}
                          variant="body"
                          component="div"
                          color="white"
                        >
                          Call Us
                        </Typography> */}
                        <Typography
                          className={classes.itemDetailsValue}
                          variant="body"
                          component="div"
                          color="white"
                        >
                          +1 202-918-2132
                        </Typography>
                      </div>
                    </div>
                    <div className={classes.contactUsItem}>
                      <div>
                        <LocationOnOutlinedIcon sx={{color:'#fff'}} />
                      </div>
                      <div className={classes.itemDetailsContainer}>
                        {/* <Typography
                          className={classes.itemDetailsLabel}
                          variant="body"
                          component="div"
                          color="white"
                        >
                          Address
                        </Typography> */}
                        <Typography
                          className={classes.itemDetailsValue}
                          variant="body"
                          component="div"
                          color="white"
                        >
                          123, OrderBazaar Street, Chennai, India.
                        </Typography>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={2.4} lg={2.4} xl={2.5}>
                  <Typography variant="h6" color="white" sx={{fontSize:18}}>
                  Connect With Us
                  </Typography>
                  {/* <Box component={"div"} className={classes.serviceDivider} /> */}
                  <div className="" style={{marginTop:26}}>
                  <Grid className="h_soci" sx={{}}>
                    <Link className={classes.itemDetailsLabel} href="#">
                      <FacebookOutlinedIcon />
                    </Link>
                    <Link className={classes.itemDetailsLabel} href="#">
                      <InstagramIcon />
                    </Link>
                    <Link className={classes.itemDetailsLabel} href="#">
                      <XIcon />
                    </Link>
                    <Link className={classes.itemDetailsLabel} href="#">
                      <LinkedInIcon />
                    </Link>
                  </Grid>
                  </div>
                  <div className={classes.appsContainer}>
                    {/* <Typography variant="h6" color="white">
                      Download App
                    </Typography> */}
                    <div className={classes.appsItem}>
                      <img
                        style={{width:'120px'}}
                        className={classes.appImages}
                        src={appStoreImage}
                        alt="App Store"
                      />
                      <img
                        style={{width:'120px'}}
                        className={classes.appImages}
                        src={playStoreImage}
                        alt="App Store"
                      />
                    </div>
                  </div>
                </Grid>
                {/* <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                  <div className={classes.circleOne}></div>
                  <div className={classes.circleTwo}></div>
                </Grid> */}
                <Grid item xs={12} style={{ textAlign: "left", marginTop: "25px" }}>
                  <Divider />
                  <Grid sx={{ mt: 4, mb: 2 }}>
                    <Grid className="pop_ct" style={{paddingLeft:0}}>
                      <Link className="" href="#">
                        Online Shopping
                      </Link>
                      <Link className="" href="#">
                        Promotions 
                      </Link>
                      <Link className="" href="#">
                        Site Map
                      </Link>
                      <Link className="" href="#">
                      Orders and Returns 
                      </Link>
                      <Link className="" href="#">
                      Help 
                      </Link>
                      <Link className="" href="#">
                      Contact Us 
                      </Link>
                      <Link className="" href="#">
                      Support 
                      </Link>
                      <Link className="" href="#">
                      Most Populars 
                      </Link>
                      <Link className="" href="#">
                      New Arrivals 
                      </Link>
                      <Link className="" href="#">
                      Special Products 
                      </Link>
                      <Link className="" href="#">
                      Manufacturers 
                      </Link>
                      <Link className="" href="#">
                      Shipping 
                      </Link>
                      <Link className="" href="#">
                      Payments 
                      </Link>
                      <Link className="" href="#">
                      Returns 
                      </Link>
                      <Link className="" href="#">
                      Refunds 
                      </Link>                    
                      <Link className="" href="#">
                      Warantee 
                      </Link>
                      <Link className="" href="#">
                      Promotions 
                      </Link>
                      <Link className="" href="#">
                      Customer Service 
                      </Link>
                      <Link className="" href="#">
                      Our Stores 
                      </Link>
                      <Link className="" href="#">
                      Discount 
                      </Link>
                      <Link className="" href="#">
                      Checkout 
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{backgroundColor: "#0d3455",overflow:'hidden',position:'relative',pt:2}}>
      <Grid container spacing={2} sx={{justifyContent:'center'}}>
        <Grid item xs={12} sm={12} md={11.1} className="ft_st">
          <Container maxWidth="xl" className="">
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                style={{ textAlign: "left",alignSelf:'center'  }}
              >
                <Typography
                  variant="body2"
                  className=""
                  sx={{mt:0,mb:1,fontWeight:300,color:'#dedede'}}
                >
                  © {currentYear} All rights reserved. OrderBazaar.
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                sx={{ textAlign: "right",alignSelf:'center' }}
              >
                <img style={{width:'220px',marginBottom:15}} className="" src={Payment_logo}/>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
      </Box>
    </Box>
  </>
  );
};

export default Footer;
