import React, { useState, useEffect } from "react";
import axios from "axios";
import { getAuth } from "firebase/auth";
import AppLayout from "../../appLayout";
import Button from "../../shared/button/button";
import { buttonTypes } from "../../shared/button/utils";
import styles from "../../../styles/profile/profile.module.scss"; // Importing styles
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
  Container
} from "@mui/material";
import { Image, PhotoCamera } from "@mui/icons-material";

export default function ProfileEdit() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    gender: "",
    occupation: "",
    about: "",
    photo: null,
  });
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const auth = getAuth();
  const user = auth.currentUser;

  // Fetch existing profile data from MongoDB based on Firebase UID
  useEffect(() => {
    if (user) {
      axios
        .post("/api/profile", { userId: user.uid })
        .then((response) => {
          setFormData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching profile:", error);
          setLoading(false);
        });
    }
  }, [user]);

  // Handle form data changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle file upload change
  const handleFileChange = (e) => {
    setFormData({ ...formData, photo: e.target.files[0] });
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file)); // Create image preview URL
    }
  };

  // Validate fields
  const validate = () => {
    let tempErrors = {};
    if (!formData.name) tempErrors.name = "Name is required";
    if (!formData.phone || !/^[0-9]{10}$/.test(formData.phone))
      tempErrors.phone = "Valid phone number is required";
    if (!formData.city) tempErrors.city = "City is required";
    if (!formData.state) tempErrors.state = "State is required";
    if (!formData.zipCode || !/^[0-9]{5,6}$/.test(formData.zipCode))
      tempErrors.zipCode = "Valid Zip Code is required";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const formDataToSubmit = new FormData();
      formDataToSubmit.append("userId", user.uid);
      formDataToSubmit.append("name", formData.name);
      formDataToSubmit.append("email", formData.email);
      formDataToSubmit.append("phone", formData.phone);
      formDataToSubmit.append("address", formData.address);
      formDataToSubmit.append("city", formData.city);
      formDataToSubmit.append("state", formData.state);
      formDataToSubmit.append("zipCode", formData.zipCode);
      formDataToSubmit.append("gender", formData.gender);
      formDataToSubmit.append("occupation", formData.occupation);
      formDataToSubmit.append("about", formData.about);
      if (formData.photo) formDataToSubmit.append("photo", formData.photo);

      axios
        .post("/api/profile", formDataToSubmit, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          alert("Profile updated successfully");
          window.location.href = "/profile"; // Redirect to profile view page
        })
        .catch((error) => {
          console.error("Error updating profile:", error);
        });
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Grid sx={{backgroundColor:'#FBFBFB'}}>
      <AppLayout>
        <Container maxWidth="xl" sx={{justifyContent:'center',}}>
          <Grid container spacing={3} className="" sx={{justifyContent:'center',}}>
            <Grid item xs={12} sm={11} md={10} sx={{alignSelf:'center'}}>
              <Typography variant="h2" fontWeight={500} sx={{ mt:5, pb:2, fontSize: 35, fontWeight: 600 }}>
                Edit Profile
              </Typography>
              <form
                onSubmit={handleSubmit}
                // className={`container pt-5 ${styles.profile_form}`}
                className={`${styles.profile_form}`}
              >
                <Grid className="ed_pf_bx">
                  <Grid className="up_l_pd">
                    {/* Image Upload */}
                    {/* {selectedImage && ( */}
                      <Box sx={{display:'flex'}}>
                        <Avatar
                          alt="Profile Picture"
                          src={selectedImage}
                          sx={{ width: 110, height: 110 }}
                        />
                        <Grid sx={{alignSelf:'center', pl:2}}>
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="label"
                          >
                            {/* <PhotoCamera /> */}
                            Upload new photo
                            <input
                              type="file"
                              accept="image/*"
                              hidden
                              onChange={handleFileChange}
                            />
                          </IconButton>
                          <Typography variant="body2" sx={{ fontSize:14, fontWeight: 400, color:'#808080', mt:1 }}>At least 800 800 px recommended.</Typography>
                          <Typography variant="body2" sx={{ fontSize:14, fontWeight: 400, color:'#808080', }}>JPG or PNG is allowed</Typography>
                        </Grid>
                      </Box>
                    {/* )} */}
                  </Grid>
                </Grid>
                <Grid className="ed_pf_bx" sx={{ mt: 5 }}>
                  <Grid container spacing={3} sx={{justifyContent:'center',}}>
                    <Grid item xs={12} sm={6} className="prof_up_in" sx={{alignSelf:'center'}}>
                      <Typography variant="body2" fontWeight={400}>
                        Name
                      </Typography>
                      <TextField
                        type="text"
                        name="name"
                        placeholder="Enter Your Name"
                        className={`form-control ${styles.form_control}`}
                        value={formData.name}
                        onChange={handleChange}
                        required
                        variant="outlined"
                      />
                      {errors.name && (
                        <span className={styles.error}>{errors.name}</span>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} className="prof_up_in" sx={{alignSelf:'center'}}>
                      <Typography variant="body2" fontWeight={400}>
                        Email
                      </Typography>
                      <TextField
                        type="email"
                        name="email"
                        placeholder="Enter Your Email"
                        className={`form-control ${styles.form_control}`}
                        value={formData.email}
                        disabled
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} className="prof_up_in" sx={{alignSelf:'center'}}>
                      <Typography variant="body2" fontWeight={400}>
                        Phone Number
                      </Typography>
                      <TextField
                          type="text"
                          name="phone"
                          placeholder="Enter Your Phone Number"
                          className={`form-control ${styles.form_control}`}
                          value={formData.phone}
                          onChange={handleChange}
                          required
                          variant="outlined"
                        />
                        {errors.phone && (
                          <span className={styles.error}>{errors.phone}</span>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6} className="prof_up_in" sx={{alignSelf:'center'}}>
                      <Typography variant="body2" fontWeight={400} sx={{mb:1}}>
                        Gender (optional)
                      </Typography>
                      <Box flex={1}>
                        <Stack direction="row" spacing={2}>
                          <label>
                            <input
                              type="radio"
                              name="gender"
                              value="male"
                              checked={formData.gender === "male"}
                              onChange={handleChange}
                            />{" "}
                            Male
                          </label>
                          <label>
                            <input
                              type="radio"
                              name="gender"
                              value="female"
                              checked={formData.gender === "female"}
                              onChange={handleChange}
                            />{" "}
                            Female
                          </label>
                          <label>
                            <input
                              type="radio"
                              name="gender"
                              value="other"
                              checked={formData.gender === "other"}
                              onChange={handleChange}
                            />{" "}
                            Other
                          </label>
                        </Stack>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} className="prof_up_in" sx={{alignSelf:'center'}}>
                      <Typography variant="body2" fontWeight={400}>
                        Occupation (optional)
                      </Typography>
                      <TextField
                        type="text"
                        name="occupation"
                        placeholder="Enter Your Occupation"
                        className={`form-control ${styles.form_control}`}
                        value={formData.occupation}
                        onChange={handleChange}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} className="prof_up_in" sx={{alignSelf:'center'}}>
                      <Typography variant="body2" fontWeight={400}>
                      Address
                      </Typography>
                      <TextField
                        type="text"
                        name="address"
                        placeholder="Enter Your Address"
                        className={`form-control ${styles.form_control}`}
                        value={formData.address}
                        onChange={handleChange}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} className="prof_up_in" sx={{alignSelf:'center'}}>
                      <Typography variant="body2" fontWeight={400}>
                      City
                      </Typography>
                      <TextField
                        type="text"
                        name="city"
                        placeholder="Enter Your City"
                        className={`form-control ${styles.form_control}`}
                        value={formData.city}
                        onChange={handleChange}
                        required
                        variant="outlined"
                      />
                      {errors.city && (
                        <span className={styles.error}>{errors.city}</span>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={4} className="prof_up_in" sx={{alignSelf:'center'}}>
                      <Typography variant="body2" fontWeight={400}>
                      State
                      </Typography>
                      <TextField
                        type="text"
                        name="state"
                        placeholder="Enter Your State"
                        className={`form-control ${styles.form_control}`}
                        value={formData.state}
                        onChange={handleChange}
                        required
                        variant="outlined"
                      />
                      {errors.state && (
                        <span className={styles.error}>{errors.state}</span>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={4} className="prof_up_in" sx={{alignSelf:'center'}}>
                      <Typography variant="body2" fontWeight={400}>
                      Zip Code
                      </Typography>
                      <TextField
                        type="text"
                        name="zipCode"
                        placeholder="Enter Your Zip Code"
                        className={`form-control ${styles.form_control}`}
                        value={formData.zipCode}
                        onChange={handleChange}
                        required
                        variant="outlined"
                      />
                      {errors.zipCode && (
                        <span className={styles.error}>{errors.zipCode}</span>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} className="prof_up_text" sx={{alignSelf:'center'}}>
                      <Typography variant="body2" fontWeight={400}>
                        About Yourself (optional)
                      </Typography>
                      <TextField
                        name="about"
                        className={`form-control ${styles.form_control}`}
                        placeholder="Write Yourself"
                        value={formData.about}
                        onChange={handleChange}
                        multiline
                        rows={4}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid sx={{ mt:5, mb:8 }} className="pr_suv">
                  <Button
                    button_type={buttonTypes.primary}
                    button_text="Save"
                    type="submit"
                  />
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Container>
      </AppLayout>
    </Grid>
  );
}
