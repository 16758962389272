import React, { useState, useContext } from "react";
import { getAuth, updatePassword, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import styles from "../../../styles/auth/auth.module.scss";
import { buttonTypes } from "../../shared/button/utils";
import Button from "../../shared/button/button";
import PasswordInput from "../../shared/passwordInput/input";
import ErrorMessage from "../../shared/error-message/errorMessage";
import { ToastContext } from "../../../context/toastContext";
import { toast_actions, toast_types } from "../../shared/toast/utils/toast";
import AppLayout from "../../appLayout";  // Assuming you have a common layout component
import { Grid, Container, Typography } from "@mui/material";

export default function ChangePassword() {
  const auth = getAuth();
  const user = auth.currentUser;
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [inlineError, setInlineError] = useState({
    currentPassword_error: "",
    newPassword_error: "",
    confirmPassword_error: "",
  });
  const dispatch = useContext(ToastContext);

  function checkPasswords() {
    let isValid = true;

    if (!newPassword || newPassword.length < 8) {
      setInlineError((inlineError) => ({
        ...inlineError,
        newPassword_error: "New password cannot be less than 8 characters",
      }));
      isValid = false;
    }

    if (newPassword === currentPassword) {
      setInlineError((inlineError) => ({
        ...inlineError,
        newPassword_error: "New password cannot be the same as the current password",
      }));
      isValid = false;
    }

    if (newPassword !== confirmPassword) {
      setInlineError((inlineError) => ({
        ...inlineError,
        confirmPassword_error: "Passwords do not match",
      }));
      isValid = false;
    }

    return isValid;
  }

  const handleChangePassword = async (e) => {
    e.preventDefault();

    if (!checkPasswords()) return;

    setLoading(true);
    try {
      const credential = EmailAuthProvider.credential(user.email, currentPassword);
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, newPassword);
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.success,
          message: "Password updated successfully",
        },
      });
    } catch (error) {
      const errorMessage = error.message || "An error occurred while updating the password.";
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: errorMessage,
        },
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid sx={{backgroundColor:'#FBFBFB'}}>
      <AppLayout>
        <Container maxWidth="xl" sx={{justifyContent:'center',}}>
          <Grid container spacing={3} className="" sx={{justifyContent:'center',}}>
            <Grid item xs={12} sm={10} md={6} sx={{alignSelf:'center'}}>
              <Typography variant="h2" fontWeight={500} sx={{ mt:5, pb:2, fontSize: 35, fontWeight: 600 }}>
                Change Password
              </Typography>
              <div className="">
                <form onSubmit={handleChangePassword}>
                  <Grid className="log_card ch_psd" sx={{ mt: 3 }}>
                    <PasswordInput
                      id="currentPassword"
                      name="currentPassword"
                      placeholder="Enter Current Password"
                      label_name="Current Password"
                      autoComplete="off"
                      has_error={inlineError.currentPassword_error}
                      onChange={(event) => {
                        setCurrentPassword(event.target.value);
                        setInlineError((inlineError) => ({
                          ...inlineError,
                          currentPassword_error: "",
                        }));
                      }}
                      required
                    />
                    {inlineError.currentPassword_error && (
                      <ErrorMessage>{inlineError.currentPassword_error}</ErrorMessage>
                    )}
                    <PasswordInput
                      id="newPassword"
                      name="newPassword"
                      placeholder="Enter New Password"
                      label_name="New Password"
                      autoComplete="off"
                      has_error={inlineError.newPassword_error}
                      onChange={(event) => {
                        setNewPassword(event.target.value);
                        setInlineError((inlineError) => ({
                          ...inlineError,
                          newPassword_error: "",
                        }));
                      }}
                      required
                    />
                    {inlineError.newPassword_error && (
                      <ErrorMessage>{inlineError.newPassword_error}</ErrorMessage>
                    )}
                    <PasswordInput
                      id="confirmPassword"
                      name="confirmPassword"
                      placeholder="Confirm New Password"
                      label_name="Confirm New Password"
                      autoComplete="off"
                      has_error={inlineError.confirmPassword_error}
                      onChange={(event) => {
                        setConfirmPassword(event.target.value);
                        setInlineError((inlineError) => ({
                          ...inlineError,
                          confirmPassword_error: "",
                        }));
                      }}
                      required
                    />
                    {inlineError.confirmPassword_error && (
                      <ErrorMessage>{inlineError.confirmPassword_error}</ErrorMessage>
                    )}
                  </Grid>
                  <Grid sx={{ mt:5, mb:8 }} className="pr_suv">
                    <Button
                      isloading={loading ? 1 : 0}
                      disabled={loading}
                      button_type={buttonTypes.primary}
                      button_hover_type={buttonTypes.primary_hover}
                      button_text="Change Password"
                      type="submit"
                    />
                  </Grid>
                </form>
              </div>
            </Grid>
          </Grid>
        </Container>
      </AppLayout>
    </Grid>
  );
}

