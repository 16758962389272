import React, { Fragment, useEffect, useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Typography, Container, Grid, Divider } from "@mui/material";

import styles from "../../../styles/profile/profile.module.scss";
import { getAuth } from "firebase/auth";
import AppLayout from "../../appLayout";
import Button from "../../shared/button/button";
import { buttonTypes } from "../../shared/button/utils";
import { useHistory } from "react-router-dom"; // Import useNavigate
import useCancellablePromise from "../../../api/cancelRequest";
import { postCall } from "../../../api/axios";
import banner from "../../../assets/images/bnr_pr.png"; 
import avt from "../../../assets/images/avt.png"; 

export default function Profile() {
  const { cancellablePromise } = useCancellablePromise();

  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isNewUser, setIsNewUser] = useState(false); // To track if profile data is incomplete
  const auth = getAuth();

  const user = auth.currentUser;
  const history = useHistory();

  // Fetch the profile data from MongoDB
  useEffect(() => {
    if (auth.currentUser) {
      getUserProfileData();
    }
  }, [auth.currentUser]);

  const getUserProfileData = async () => {
    try {
      const data = await cancellablePromise(
        postCall(
          `/api/profile`,
          JSON.stringify({ userId: auth.currentUser?.uid })
        )
      );
      if (!data || !data.phone || !data.address) {
        setIsNewUser(true); // Mark as new user if profile fields are missing
      } else {
        setProfileData(data); // Set the fetched profile data
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  // Loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  // If new user, prompt for more information
  if (isNewUser) {
    return (
      <AppLayout>
        <div className="container pt-5">
          <h3>Welcome! Please complete your profile information:</h3>
          <Button
            button_type={buttonTypes.primary}
            onClick={() => history.push("/edit-profile")} // Redirect to edit profile page
            button_text="Complete Your Profile"
          />
        </div>
      </AppLayout>
    );
  }

  return (
    <AppLayout>
      <Container maxWidth="xl" sx={{}}>
        <Grid container spacing={3} className="" sx={{justifyContent:'center',}}>
          <Grid item xs={12} sm={12} md={11} sx={{alignSelf:'center'}}>
            <Typography variant="h2" fontWeight={500} sx={{ mt:5, pb:2, fontSize: 35, fontWeight: 600 }}>
              Profile Information
            </Typography>
            <Grid>
              <img src={banner} alt="Banner" style={{ height: "190px",objectFit:'cover',width:'100%',borderRadius:'10px 10px 0px 0px' }}/>
            </Grid>
            <Grid sx={{textAlign:'left',px:3}}>
              <div className="">
                <div className="prof_i">
                  {profileData?.photoURL ? (
                    <img
                      src={profileData?.photoURL ?? "/path/to/default/avatar.png"}
                      alt="user_avatar"
                      className={styles.avatar_image}
                    />
                  ) : (
                    // <AccountCircleIcon sx={{ fontSize: 150, color: "GrayText" }} />
                    <img src={avt}
                      alt="avatar"
                      className={styles.avatar_image}
                    />
                  )}
                </div>
                <div className="">
                  <Typography variant="body2" sx={{ fontSize:22, fontWeight: 600, my:0.5 }}>
                    {profileData?.name || user.displayName}
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize:14, fontWeight: 400, color:'#808080' }}>This will be displayed on your profile</Typography>
                  <Divider sx={{ my:2, borderColor:'#808080' }} />
                  <Typography variant="body2" sx={{ fontSize:14, fontWeight: 400, color:'#808080', mb:0 }}>Email</Typography>
                  <Typography variant="body2" sx={{ fontSize:16, fontWeight: 600, color:'#000', mb:2 }}>{user.email}</Typography>
                  <Typography variant="body2" sx={{ fontSize:14, fontWeight: 400, color:'#808080', mb:0 }}>Phone Number</Typography>
                  <Typography variant="body2" sx={{ fontSize:16, fontWeight: 600, color:'#000', mb:2 }}>{profileData?.phone}</Typography>
                  <Typography variant="body2" sx={{ fontSize:14, fontWeight: 400, color:'#808080', mb:0 }}>Address</Typography>
                  <Typography variant="body2" sx={{ fontSize:16, fontWeight: 600, color:'#000', mb:2 }}>{profileData?.address}</Typography>
                  <Typography variant="body2" sx={{ fontSize:14, fontWeight: 400, color:'#808080', mb:0 }}>City</Typography>
                  <Typography variant="body2" sx={{ fontSize:16, fontWeight: 600, color:'#000', mb:2 }}>{profileData?.city}</Typography>
                  <Typography variant="body2" sx={{ fontSize:14, fontWeight: 400, color:'#808080', mb:0 }}>State</Typography>
                  <Typography variant="body2" sx={{ fontSize:16, fontWeight: 600, color:'#000', mb:2 }}>{profileData?.state}</Typography>
                  <Typography variant="body2" sx={{ fontSize:14, fontWeight: 400, color:'#808080', mb:0 }}>ZipCode</Typography>
                  <Typography variant="body2" sx={{ fontSize:16, fontWeight: 600, color:'#000', mb:1.5 }}>{profileData?.zipCode}</Typography>
                  <Grid sx={{ mt: 3, mb: 8 }}>
                  <Divider sx={{ mb: 3, borderColor:'#808080' }} />
                  <Button
                    button_type={buttonTypes.primary}
                    onClick={() => history.push("/edit-profile")} // Redirect to edit profile page
                    button_text="Edit Profile"
                  />
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </AppLayout>
  );
}
