import React, { useState, useRef, useEffect } from "react";
import { Grid, Card, Typography, Button, Stack } from "@mui/material";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Fruits from "../../../assets/images/fruits.png";
import Food from "../../../assets/images/product-6-2.jpg";
import Fashion from "../../../assets/images/product-7-2.jpg";
import Electronics from "../../../assets/images/electronics.png";
import Outlet from "../../../assets/images/product-9-2.jpg";
import Shopping from "../../../assets/images/women-shopping.png";
import Tag from "../../../assets/images/tag.png";
import FreeDelivery from "../../../assets/images/free-delivery.png";
import Deal from "../../../assets/images/deal.png";
import Assortment from "../../../assets/images/assortment.png";
import ProductReturn from "../../../assets/images/product-return.png";

const PopularProducts = ( ) => {
  const ref = useRef(null);


  return (
    <Grid>
      <Grid sx={{mb:2}}>
        <Typography variant="h4" style={{ marginTop: 10, }}>
        Popular Products
        </Typography>
      </Grid>
      <Grid container spacing={3} className="">
        <Grid item xs={12} sm={2.4}>
          <div className="" style={{position:'relative'}}>
          <Card className="cu_card" sx={{pb:4.5}}>
            <Grid className="wis_io">
              <Typography variant="caption" className="">
                <FavoriteBorderIcon/>
              </Typography>
            </Grid>
            <Stack sx={{
              
            }} p={2} justifyContent="space-between">
              <img
                className="" src={Fruits} alt=""
                sx={{
                  margin: "auto",
                }}
              />
              <Stack spacing={1}>
                <Typography variant="h6" className="ell_single" sx={{ fontSize: 14, pt:2 }}>
                product_name
                </Typography>
                <Typography variant="subtitle1">Seaways</Typography>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h6" className="" sx={{mt:0}}>
                  ₹220
                  </Typography>
                </Stack>
              </Stack>

            </Stack>
          </Card>
          <div className="ad_cr_gir" style={{ position:'relative' }}>
            <Button variant="contained" size="small">
              Add to cart
            </Button>
          </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={2.4}>
          <div className="" style={{position:'relative'}}>
          <Card className="cu_card" sx={{pb:4.5}}>
            <Grid className="wis_io">
              <Typography variant="caption" className="">
                <FavoriteBorderIcon/>
              </Typography>
            </Grid>
            <Stack sx={{
              
            }} p={2} justifyContent="space-between">
              <img
                className="" src={Food} alt=""
                sx={{
                  objectFit: "cover",
                  margin: "auto",
                }}
              />
              <Stack spacing={1}>
                <Typography variant="h6" className="ell_single" sx={{ fontSize: 14, pt:2 }}>
                product_name
                </Typography>
                <Typography variant="subtitle1">Seaways</Typography>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h6" className="" sx={{mt:0}}>
                  ₹220
                  </Typography>
                </Stack>
              </Stack>

            </Stack>
          </Card>
          <div className="ad_cr_gir" style={{ position:'relative' }}>
            <Button variant="contained" size="small">
              Add to cart
            </Button>
          </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={2.4}>
          <div className="" style={{position:'relative'}}>
          <Card className="cu_card" sx={{pb:4.5}}>
            <Grid className="wis_io">
              <Typography variant="caption" className="">
                <FavoriteBorderIcon/>
              </Typography>
            </Grid>
            <Stack sx={{
              
            }} p={2} justifyContent="space-between">
              <img
                className="" src={Fashion} alt=""
                sx={{
                  objectFit: "cover",
                  margin: "auto",
                }}
              />
              <Stack spacing={1}>
                <Typography variant="h6" className="ell_single" sx={{ fontSize: 14, pt:2 }}>
                product_name
                </Typography>
                <Typography variant="subtitle1">Seaways</Typography>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h6" className="" sx={{mt:0}}>
                  ₹220
                  </Typography>
                </Stack>
              </Stack>

            </Stack>
          </Card>
          <div className="ad_cr_gir" style={{ position:'relative' }}>
            <Button variant="contained" size="small">
              Add to cart
            </Button>
          </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={2.4}>
          <div className="" style={{position:'relative'}}>
          <Card className="cu_card" sx={{pb:4.5}}>
            <Grid className="wis_io">
              <Typography variant="caption" className="">
                <FavoriteBorderIcon/>
              </Typography>
            </Grid>
            <Stack sx={{
              
            }} p={2} justifyContent="space-between">
              <img
                className="" src={Electronics} alt=""
                sx={{
                  objectFit: "cover",
                  margin: "auto",
                }}
              />
              <Stack spacing={1}>
                <Typography variant="h6" className="ell_single" sx={{ fontSize: 14, pt:2 }}>
                product_name
                </Typography>
                <Typography variant="subtitle1">Seaways</Typography>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h6" className="" sx={{mt:0}}>
                  ₹220
                  </Typography>
                </Stack>
              </Stack>

            </Stack>
          </Card>
          <div className="ad_cr_gir" style={{ position:'relative' }}>
            <Button variant="contained" size="small">
              Add to cart
            </Button>
          </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={2.4}>
          <div className="" style={{position:'relative'}}>
          <Card className="cu_card" sx={{pb:4.5}}>
            <Grid className="wis_io">
              <Typography variant="caption" className="">
                <FavoriteBorderIcon/>
              </Typography>
            </Grid>
            <Stack sx={{
              
            }} p={2} justifyContent="space-between">
              <img
                className="" src={Outlet} alt=""
                sx={{
                  objectFit: "cover",
                  margin: "auto",
                }}
              />
              <Stack spacing={1}>
                <Typography variant="h6" className="ell_single" sx={{ fontSize: 14, pt:2 }}>
                product_name
                </Typography>
                <Typography variant="subtitle1">Seaways</Typography>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h6" className="" sx={{mt:0}}>
                  ₹220
                  </Typography>
                </Stack>
              </Stack>

            </Stack>
          </Card>
          <div className="ad_cr_gir" style={{ position:'relative' }}>
            <Button variant="contained" size="small">
              Add to cart
            </Button>
          </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={2.4}>
          <div className="" style={{position:'relative'}}>
          <Card className="cu_card" sx={{pb:4.5}}>
            <Grid className="wis_io">
              <Typography variant="caption" className="">
                <FavoriteBorderIcon/>
              </Typography>
            </Grid>
            <Stack sx={{
              
            }} p={2} justifyContent="space-between">
              <img
                className="" src={Outlet} alt=""
                sx={{
                  objectFit: "cover",
                  margin: "auto",
                }}
              />
              <Stack spacing={1}>
                <Typography variant="h6" className="ell_single" sx={{ fontSize: 14, pt:2 }}>
                product_name
                </Typography>
                <Typography variant="subtitle1">Seaways</Typography>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h6" className="" sx={{mt:0}}>
                  ₹220
                  </Typography>
                </Stack>
              </Stack>

            </Stack>
          </Card>
          <div className="ad_cr_gir" style={{ position:'relative' }}>
            <Button variant="contained" size="small">
              Add to cart
            </Button>
          </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={2.4}>
          <div className="" style={{position:'relative'}}>
          <Card className="cu_card" sx={{pb:4.5}}>
            <Grid className="wis_io">
              <Typography variant="caption" className="">
                <FavoriteBorderIcon/>
              </Typography>
            </Grid>
            <Stack sx={{
              
            }} p={2} justifyContent="space-between">
              <img
                className="" src={Fruits} alt=""
                sx={{
                  margin: "auto",
                }}
              />
              <Stack spacing={1}>
                <Typography variant="h6" className="ell_single" sx={{ fontSize: 14, pt:2 }}>
                product_name
                </Typography>
                <Typography variant="subtitle1">Seaways</Typography>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h6" className="" sx={{mt:0}}>
                  ₹220
                  </Typography>
                </Stack>
              </Stack>

            </Stack>
          </Card>
          <div className="ad_cr_gir" style={{ position:'relative' }}>
            <Button variant="contained" size="small">
              Add to cart
            </Button>
          </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={2.4}>
          <div className="" style={{position:'relative'}}>
          <Card className="cu_card" sx={{pb:4.5}}>
            <Grid className="wis_io">
              <Typography variant="caption" className="">
                <FavoriteBorderIcon/>
              </Typography>
            </Grid>
            <Stack sx={{
              
            }} p={2} justifyContent="space-between">
              <img
                className="" src={Food} alt=""
                sx={{
                  objectFit: "cover",
                  margin: "auto",
                }}
              />
              <Stack spacing={1}>
                <Typography variant="h6" className="ell_single" sx={{ fontSize: 14, pt:2 }}>
                product_name
                </Typography>
                <Typography variant="subtitle1">Seaways</Typography>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h6" className="" sx={{mt:0}}>
                  ₹220
                  </Typography>
                </Stack>
              </Stack>

            </Stack>
          </Card>
          <div className="ad_cr_gir" style={{ position:'relative' }}>
            <Button variant="contained" size="small">
              Add to cart
            </Button>
          </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={2.4}>
          <div className="" style={{position:'relative'}}>
          <Card className="cu_card" sx={{pb:4.5}}>
            <Grid className="wis_io">
              <Typography variant="caption" className="">
                <FavoriteBorderIcon/>
              </Typography>
            </Grid>
            <Stack sx={{
              
            }} p={2} justifyContent="space-between">
              <img
                className="" src={Fashion} alt=""
                sx={{
                  objectFit: "cover",
                  margin: "auto",
                }}
              />
              <Stack spacing={1}>
                <Typography variant="h6" className="ell_single" sx={{ fontSize: 14, pt:2 }}>
                product_name
                </Typography>
                <Typography variant="subtitle1">Seaways</Typography>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h6" className="" sx={{mt:0}}>
                  ₹220
                  </Typography>
                </Stack>
              </Stack>

            </Stack>
          </Card>
          <div className="ad_cr_gir" style={{ position:'relative' }}>
            <Button variant="contained" size="small">
              Add to cart
            </Button>
          </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={2.4}>
          <div className="" style={{position:'relative'}}>
          <Card className="cu_card" sx={{pb:4.5}}>
            <Grid className="wis_io">
              <Typography variant="caption" className="">
                <FavoriteBorderIcon/>
              </Typography>
            </Grid>
            <Stack sx={{
              
            }} p={2} justifyContent="space-between">
              <img
                className="" src={Electronics} alt=""
                sx={{
                  objectFit: "cover",
                  margin: "auto",
                }}
              />
              <Stack spacing={1}>
                <Typography variant="h6" className="ell_single" sx={{ fontSize: 14, pt:2 }}>
                product_name
                </Typography>
                <Typography variant="subtitle1">Seaways</Typography>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h6" className="" sx={{mt:0}}>
                  ₹220
                  </Typography>
                </Stack>
              </Stack>

            </Stack>
          </Card>
          <div className="ad_cr_gir" style={{ position:'relative' }}>
            <Button variant="contained" size="small">
              Add to cart
            </Button>
          </div>
          </div>
        </Grid>
      </Grid>
      <Grid className="po_bann" sx={{ mt: 14 }}>
        <Grid container spacing={3} className="">
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" sx={{ fontSize: 35, fontWeight: 600, lineHeight: 1.3 }}>
              Stay Home and Meet Your Daily Needs With Ease – Shop With Us!
            </Typography>
            <Typography variant="body2" sx={{ fontSize:16, fontWeight: 400, lineHeight:1.5, mt: 3 }}>
            Stay home and let us take care of your daily needs. With our shop, you can enjoy a seamless shopping experience from the comfort of your home. From essentials to indulgences, we offer a wide range of quality products to meet your requirements. Trust us to deliver everything you need right to your doorstep, making life easier and more convenient.
            </Typography>
            <Button variant="contained" sx={{ px: 5, mt: 3 }}>Shop Now</Button>
          </Grid>
          <Grid item xs={12} sm={6} className="nw_in">
            <img src={Shopping} alt=""/>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ mt: 5 }}>
        <Grid item xs={12} sm={2.4}>
          <Grid sx={{ display:'flex' }} className="off_sspl">
            <img src={Tag} alt=""/>
            <Grid sx={{ alignSelf:'center', pl:1 }}>
            <Typography variant="body2" sx={{ fontSize:18, fontWeight: 500, lineHeight:1.3 }}>
              Best prices & offers
            </Typography>
            <Typography variant="body2" sx={{ fontSize:14, fontWeight: 300, lineHeight:1.3, color:'#888888', mt:1 }}>
              Orders $50 or more
            </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={2.4}>
          <Grid sx={{ display:'flex' }} className="off_sspl">
            <img src={FreeDelivery} alt=""/>
            <Grid sx={{ alignSelf:'center', pl:1 }}>
            <Typography variant="body2" sx={{ fontSize:18, fontWeight: 500, lineHeight:1.3 }}>
            Free delivery
            </Typography>
            <Typography variant="body2" sx={{ fontSize:14, fontWeight: 300, lineHeight:1.3, color:'#888888', mt:1 }}>
            24/7 amazing services
            </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={2.4}>
          <Grid sx={{ display:'flex' }} className="off_sspl">
            <img src={Deal} alt=""/>
            <Grid sx={{ alignSelf:'center', pl:1 }}>
            <Typography variant="body2" sx={{ fontSize:18, fontWeight: 500, lineHeight:1.3 }}>
            Great daily deal
            </Typography>
            <Typography variant="body2" sx={{ fontSize:14, fontWeight: 300, lineHeight:1.3, color:'#888888', mt:1 }}>
            When you sign up
            </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={2.4}>
          <Grid sx={{ display:'flex' }} className="off_sspl">
            <img src={Assortment} alt=""/>
            <Grid sx={{ alignSelf:'center', pl:1 }}>
            <Typography variant="body2" sx={{ fontSize:18, fontWeight: 500, lineHeight:1.3 }}>
            Wide assortment
            </Typography>
            <Typography variant="body2" sx={{ fontSize:14, fontWeight: 300, lineHeight:1.3, color:'#888888', mt:1 }}>
            Mega Discounts
            </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={2.4}>
          <Grid sx={{ display:'flex' }} className="off_sspl">
            <img src={ProductReturn} alt=""/>
            <Grid sx={{ alignSelf:'center', pl:1 }}>
            <Typography variant="body2" sx={{ fontSize:18, fontWeight: 500, lineHeight:1.3 }}>
            Easy returns
            </Typography>
            <Typography variant="body2" sx={{ fontSize:14, fontWeight: 300, lineHeight:1.3, color:'#888888', mt:1 }}>
            Within 15 days
            </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PopularProducts;
