import React, { useEffect, useState } from "react";

import CategoriesHeader from "../category/categories/categories";
import SubCategoriesHeader from "../product/subCategories/subCategories";
import SubCategories from "../category/subCategories/subCategories";
import CategoryBrands from "../category/brands/brands";
import ProductList from "../product/productList/productList";

import Home from "../home/home";

import { useLocation } from "react-router-dom";
console.log("products/product.js...")

const Products = () => {
  const locationData = useLocation();
  const useQuery = () => {
    const { search } = locationData;
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };
  let query = useQuery();
  const [isCatAvailable, setIsCatAvailable] = useState(false);
  const [isSubCatAvailable, setIsSubCatAvailable] = useState(false);
  const [isSearchAvailable, setIsSearchAvailable] = useState(false);

  useEffect(() => {
    if (locationData) {
      let cat = query.get("c");
      const subCat = query.get("sc");
      const product = query.get("s");
      const cities = query.get("cities")?.split(",") || [];
      console.log("Widget Display controller cat==>>", cat)
      console.log("Widget Display controller subCat==>>", subCat)
      console.log("Widget Display controller product==>>", product)
      console.log("Main Home page Parsed Cities from Query:", cities);

      if ((cat === null || cat === "") && locationData.pathname === "/") {
        cat = "Fashion"; // Assign default category
      }
      if (cat) {
        setIsCatAvailable(true);
      } else {
        setIsCatAvailable(false);
      }
      if (subCat) {
        setIsSubCatAvailable(true);
      } else {
        setIsSubCatAvailable(false);
      }
      if ((product) || (locationData.pathname !== "/" && cities)) {
        setIsSearchAvailable(true);
      } else {
        setIsSearchAvailable(false);
      }
    }
  }, [locationData]);

  if (isSearchAvailable && isSubCatAvailable && isCatAvailable) {
    console.log("111111111111111111111111st Loop......")
    return (
      <>
        <SubCategoriesHeader />
        <ProductList />
      </>
    );
  } else if (!isSearchAvailable && isSubCatAvailable && isCatAvailable) {
    console.log("22222222222222222222222222222222nd Loop..")
    return (
      <>
        <SubCategoriesHeader />
        <ProductList />
      </>
    );
  } else if (!isSearchAvailable && !isSubCatAvailable && isCatAvailable) {
    console.log("3333333333333333333333333333333333333333rd Loop..")
    return (
      <>
        <CategoriesHeader />
        <SubCategories />
        <CategoryBrands />
      </>
    );
  } else if (isSearchAvailable && !isSubCatAvailable && !isCatAvailable) {
    console.log("444444444444444444444444444444444444444444444444th Loop..")
    return (
      <>
        <ProductList />
      </>
    );
  } else if (isSearchAvailable && !isSubCatAvailable && isCatAvailable) {
    console.log("555555555555th Loop..")
    return (
      <>
        <ProductList />
      </>
    );
  } else {
    console.log("6th...")
    return <Home />;
  }
};

export default Products;
