import React from "react";
import styles from "../../../styles/auth/auth.module.scss";
import logo from "../../../assets/images/logo_lo.png";
import {Container, Grid} from "@mui/material";

export default function AuthActionCard(props) {
  const { action_form, navigation_link } = props;
  return (
    <Grid sx={{ backgroundColor:'#FBFBFB' }}>
      <Container maxWidth="xl" sx={{mt:2}}>
        <Grid container spacing={3} className="" sx={{justifyContent:'center',minHeight:'100vh'}}>
          <Grid item xs={12} sm={12} md={4} sx={{alignSelf:'center'}}>
            <div className="d-flex align-items-center justify-content-center pb-4">
              <img src={logo} alt="logo" style={{ height: "30px" }} />
            </div>
            <div className="log_card">
              <div style={{  }}>{action_form}</div>
              <div className="d-flex align-items-center justify-content-center">
                {navigation_link}
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}
